import Input from '../../Input/Input';
import validator from "validator";

import './PersonalInfo.css';
import { CheckedIcon } from '../../../assets/icons/icons';
import { Link } from 'react-router-dom';
import { useContext } from 'react';
import { ConfigContext } from '../../../assets/contexts/configContext';



function PersonalInfo({ personal, setPersonal, personalValidity, setPersonalValidity }) {
    const config = useContext(ConfigContext)
    const { initialConstants } = config
    const { CONSTANTS } = initialConstants
    const { SHOW_DELIVERY = true } = CONSTANTS
    // phone: '',
    // first_name: '',
    // last_name: '',
    // email: '',
    // submit_type: 'phone',
    // city: '',
    // street: '',
    // house: '',
    // apartment: '',
    // type: 'delivery',

    function handleChange(e) {
        const input = e.target;
        const name = input.name;
        let value = input.value;

        switch (name) {
            case "phone":
                let inputValue = e.target.value.replace(/\D/g, '')
                let formattedInputValue = '';
                if (!inputValue) {
                    setPersonal(prevValue => ({
                        ...prevValue,
                        [name]: '',
                    }))
                    setPersonalValidity((prevValue) => ({
                        ...prevValue,
                        [name]: {
                            errorMessage: 'Можно вводить только цифры',
                            validState: false
                        }
                    }))
                }
                else {
                    if (['7', '8', '9'].indexOf(inputValue[0]) > -1) {
                        setPersonalValidity((prevValue) => ({
                            ...prevValue,
                            [name]: {
                                errorMessage: '',
                                validState: false
                            }
                        }))
                        if (inputValue[0] === '9') inputValue = '7' + inputValue;

                        let firstSimbols = (inputValue[0] === '8') ? '8' : '+7';
                        formattedInputValue = firstSimbols + ' '

                        if (inputValue.length > 1) {
                            formattedInputValue += '(' + inputValue.substring(1, 4)
                        }
                        if (inputValue.length >= 5) {
                            formattedInputValue += ') ' + inputValue.substring(4, 7)
                        }
                        if (inputValue.length >= 8) {
                            formattedInputValue += '-' + inputValue.substring(7, 9)
                        }
                        if (inputValue.length >= 10) {
                            formattedInputValue += '-' + inputValue.substring(9, 11)
                        }
                        if (inputValue.length >= 11) {
                            setPersonalValidity((prevValue) => ({
                                ...prevValue,
                                [name]: {
                                    errorMessage: '',
                                    validState: true
                                }
                            }));
                        } else {
                            setPersonalValidity((prevValue) => ({
                                ...prevValue,
                                [name]: {
                                    errorMessage: '',
                                    validState: false
                                }
                            }));
                        }
                    } else {
                        formattedInputValue = '+' + inputValue.substring(0, 16)
                        if (inputValue.length >= 11) {
                            setPersonalValidity((prevValue) => ({
                                ...prevValue,
                                [name]: {
                                    errorMessage: 'Только номера РФ',
                                    validState: false
                                }
                            }));
                        } else {
                            setPersonalValidity((prevValue) => ({
                                ...prevValue,
                                [name]: {
                                    errorMessage: 'Только номера РФ',
                                    validState: false
                                }
                            }));
                        }
                    }


                    setPersonal(prevValue => ({
                        ...prevValue,
                        [name]: formattedInputValue,
                    }))
                }

                break;

            case "email":
                if (!value) {
                    setPersonalValidity((prevValue) => ({
                        ...prevValue,
                        [name]: {
                            errorMessage: "",
                            validState: false,
                        },
                    }));
                }
                if (value.length >= 2) {
                    if (validator.isEmail(value)) {
                        setPersonalValidity((prevValue) => ({
                            ...prevValue,
                            [name]: {
                                errorMessage: "",
                                validState: true,
                            },
                        }));
                    } else {
                        setPersonalValidity((prevValue) => ({
                            ...prevValue,
                            [name]: {
                                errorMessage: !e.target.validity.valid
                                    ? e.target.validationMessage
                                    : "Invalid email",
                                validState: false,
                            },
                        }));
                    }
                }
                setPersonal((prevValue) => ({
                    ...prevValue,
                    [name]: value,
                }));
                break;

            default:
                setPersonal((prevValue) => ({
                    ...prevValue,
                    [name]: value,
                }));
                break;
        }
    }

    function handlePhoneDelite(e) {
        if (e.keyCode === 8 && e.target.value.replace(/\D/g, '').length === 1) {
            setPersonal(prevValue => ({
                ...prevValue,
                phone: '',
            }))
        }
        if (e.keyCode === 8 && e.target.value.replace(/\D/g, '').length < 11) {
            setPersonalValidity(prevValue => ({
                ...prevValue,
                phone: {
                    errorMessage: '',
                    validState: false
                }
            }));
        }

    }

    function handleSelectType({ item, name }) {
        setPersonal(prevValue => ({
            ...prevValue,
            [name]: item.key,
        }))
    }

    function toggleCheckbox() {
        setPersonal(prevValue => ({
            ...prevValue,
            checkbox: !prevValue.checkbox,
        }))
    }

    return (
        <form className='personal-info' onSubmit={(evt) => { evt.preventDefault() }}>
            <div className='personal-info__inputs-container'>
                <h3 className='personal-info__inputs-title'>Информация о получателе</h3>
                <div className='personal-info__two-inputs'>
                    <div className="personal-info__input">
                        <Input
                            label={"Имя"}
                            value={personal.first_name}
                            handleChange={handleChange}
                            name={"first_name"}
                        // error={formValid.email}
                        ></Input>
                    </div>
                    <div className="personal-info__input">
                        <Input
                            label={"Фамилия"}
                            value={personal.last_name}
                            handleChange={handleChange}
                            name={"last_name"}
                        // error={formValid.email}
                        ></Input>
                    </div>
                </div>
            </div>
            <div className='personal-info__inputs-container'>
                <h3 className='personal-info__inputs-title'>Контактная информация</h3>
                <div className='personal-info__two-inputs'>
                    <div className="personal-info__input">
                        <Input
                            label={"Номер телефона"}
                            value={personal.phone}
                            handleChange={handleChange}
                            name={"phone"}
                            inputMode='tel'
                            error={personalValidity.phone}
                            onKeyDown={handlePhoneDelite}
                        ></Input>
                    </div>
                    <div className="personal-info__input">
                        <Input
                            label={"Эл. почта"}
                            value={personal.email}
                            handleChange={handleChange}
                            name={"email"}
                            inputMode='email'
                        // error={personalValidity.email}
                        ></Input>
                    </div>
                </div>
            </div>
            {/* <div className='personal-info__inputs-container'>
                <h3 className='personal-info__inputs-title'>Способ подтверждения</h3>
                <div className='personal-info__select-input-box' style={{
                    gridTemplateColumns: `repeat(${SUBMIT_TYPE_VALUES.length}, minMax(0, 1fr))`,
                }}>
                    {SUBMIT_TYPE_VALUES.map((item, i) => (
                        <button className={`personal-info__select-input ${item.key === personal.submit_type ? 'personal-info__select-input_selected' : ''}`} key={`personal-info__select-input_SubmitType_${i}`} type='button' onClick={() => {
                            handleSelectType({ item, name: 'submit_type' })
                        }} dangerouslySetInnerHTML={{
                            __html: item.name,
                        }}>
                        </button>
                    ))}

                </div>
            </div> */}

            <div className='personal-info__inputs-container'>
                {/* <h3 className='personal-info__inputs-title'>Адрес доставки</h3>
                <div className='personal-info__two-inputs'>
                    <div className="personal-info__input">
                        <Input
                            label={"Город"}
                            value={personal.city}
                            handleChange={handleChange}
                            name={"city"}
                        // error={formValid.email}
                        ></Input>
                    </div>
                    <div className="personal-info__input">
                        <Input
                            label={"Улица"}
                            value={personal.street}
                            handleChange={handleChange}
                            name={"street"}
                        // error={formValid.email}
                        ></Input>
                    </div>
                </div>
                <div className='personal-info__two-inputs'>
                    <div className="personal-info__input">
                        <Input
                            label={"Дом"}
                            value={personal.house}
                            handleChange={handleChange}
                            name={"house"}
                        // error={formValid.email}
                        ></Input>
                    </div>
                    <div className="personal-info__input">
                        <Input
                            label={"Квартира"}
                            value={personal.apartment}
                            handleChange={handleChange}
                            name={"apartment"}
                        // error={formValid.email}
                        ></Input>
                    </div>
                </div> */}

                {/* <div className='personal-info__inputs-container'>
                    <p className='personal-info__inputs-title'>Выберите доставку</p>
                    <div className='personal-info__select-input-box' style={{
                        gridTemplateColumns: `repeat(${DELIVERY_TYPE_VALUES.length}, minMax(0, 1fr))`,
                    }}>
                        {DELIVERY_TYPE_VALUES.map((item, i) => (
                            <button className={`personal-info__select-input ${item.key === personal.type ? 'personal-info__select-input_selected' : ''}`} key={`personal-info__select-input_SubmitType_${i}`} type='button' onClick={() => {
                                handleSelectType({ item, name: 'type' })
                            }} dangerouslySetInnerHTML={{
                                __html: item.name,
                            }}>
                            </button>
                        ))}

                    </div>
                </div> */}
                <button className={`personal-info__checkbox ${personal.checkbox ? 'personal-info__checkbox_selected' : ''}`} onClick={toggleCheckbox} type='button'>
                    <div className='personal-info__checkbox-box'>
                        <CheckedIcon mainClassName={'personal-info__checkbox-box-icon'} strokeClassName={'personal-info__checkbox-box-icon-stroke'} />
                    </div>
                    <p className='personal-info__checkbox-text'>Оформляя заказ вы соглашаетесь с условиями <Link to={'/docs/public_offer'} className='personal-info__checkbox-text-link'>оферты</Link> и даете <Link to={'/docs/user_agreement'} className='personal-info__checkbox-text-link'>согласие на обработку персональных данных</Link></p>
                </button>
                {/* <p className='personal-info__delivery-hint'>Стоимость доставки рассчитывается по&nbsp;тарифам СДЭК и&nbsp;оплачивается при получении</p> */}
            </div>

        </form>
    );
}

export default PersonalInfo